import { addToast } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ContentWrapper from "components/ContentWrapper";
import FormChangePassword from "components/FormChangePassword";
import Header from "components/Header";
import ArrowHeading from "components/Icons/Arrowheading";
import AttentionIcon from "components/Icons/Attention";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Input, { InputType } from "components/Input";
import LinkedSocialAccount from "components/LinkedSocialAccount";
import Modal from "components/Modal";
import MyAccountButton from "components/MyAccountButton";
import SelectCustom from "components/SelectCustom";
import consts from "consts/consts";
import FormChangeParentalCode from "containers/FormChangeParentalCode";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import {
  getFromLocal,
  removeFromLocal,
  saveInLocal,
} from "helpers/localStorage";
import { CapitalizeFirstLetter, showToast } from "helpers/utility";
import moment from "moment";
import React, { Component, Fragment } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BaseSelect from "react-select";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const Select = (props) => (
  <SelectCustom {...props} SelectComponent={BaseSelect} />
);

const MODAL_PASSWORD = "MODAL_PASSWORD";
const MODAL_PARENTAL_CODE = "MODAL_PARENTAL_CODE";
const MODAL_DELETE_DATA_STEP1 = "MODAL_DELETE_DATA_STEP1";
const MODAL_DELETE_DATA_STEP2 = "MODAL_DELETE_DATA_STEP2";
const MODAL_DELETE_DATA_STEP3 = "MODAL_DELETE_DATA_STEP3";
const MODAL_DELETE_DATA_ERROR = "MODAL_DELETE_DATA_ERROR";

class PageMyAccount extends Component {
  state = {
    editFormDetail: {
      data: {
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        languageId: "",
        dateofbirth: "",
        additionalInformation: "",
        allowNews: false,
      },
      required: {
        title: consts.changeDetails.required.title,
        firstName: consts.changeDetails.required.firstName,
        lastName: consts.changeDetails.required.lastName,
        email: consts.changeDetails.required.email,
        languageId: consts.changeDetails.required.languageId,
        dateofbirth: consts.changeDetails.required.dateofbirth,
        additionalInformation:
          consts.changeDetails.required.additionalInformation,
      },
    },
    editFormExtra: { data: null, required: null, info: null },
    editFormAddress: {
      data: {
        addressNumber: "",
        addressStreet: "",
        addressSecondStreet: "",
        addressThirdStreet: "",
        addressBox: "",
        floor: "",
        addressCity: "",
        addressZip: "",
        addressState: "",
        addressCountry: "",
      },
      required: {
        addressNumber: consts.changeAddress.required.addressNumber,
        addressStreet: consts.changeAddress.required.addressStreet,
        addressCity: consts.changeAddress.required.addressCity,
        addressZip: consts.changeAddress.required.addressZip,
        addressState: consts.changeAddress.required.addressState,
        addressCountry: consts.changeAddress.required.addressCountry,
        addressBox: consts.changeAddress.required.addressBox,
      },
    },
    edited: false,
    show: false,
    optionsTitle: consts.enum.title || [],
    optionsLanguage: consts.enum.languageId || [],
    optionsAddressCountry: consts.enum.addressCountry || [],
    languageIdMapping: consts.languageIdMapping,
    langEdited: false,
    emailError: null,
    PasswordToConfirm: "",
    deleteConfirmButtonSpin: false,
    deleteDataErrorMessage: "",
    DownloadDataStep: 0,
    DownloadDataError: false,
    DownloadLink: "",
  };

  errorAPIChangeAddress = {
    "-31": "Email too short",
    "-40": "First name is required",
    "-41": "Last name is required",
    "-50": "Street is required",
    "-51": "Address number is required",
    "-52": "Zip is required",
    "-53": "City is required",
    "-54": "Country is required",
    "-168": "Param box is too long",
    "-3001":
      "Invalid addressType / types allowed are: 1 - Official / 2 - Delivery / 3 - Billing / 4 - Installation",
  };

  errorAPIChangeDetails = {
    "-30": "Email is not valid",
    "-31": "Email too short",
    "-32": "Emails missmatch",
    "-40": "First name is required",
    "-41": "Last name is required",
    "-45": "You must be 18 years old to use our services",
    "-46": "Date of birth is required",
    "-60": "Language is required",
  };

  errorAPIUpdatePassword = {
    "-10": "Password too short",
    "-11": "Passwords mismatch",
    "-12": "Invalid character",
    "-13": "Invalid old password",
    "-30": "Email is not valid",
    "-31": "Email too short",
  };

  async componentDidMount() {
    const { isConnected, t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.appModules.account !== true) {
      this.props.history.replace("/");
    }

    let idJobQueue = getFromLocal("idJobQueue");
    if (isConnected && idJobQueue) {
      this.setState({ DownloadDataStep: 1 });
    }

    if (!isConnected) {
      this.props.history.push("?login=1");
    }
    if (this.props.userInfo) {
      this.loadDataUserInfo();
      if (this.props.userInfo["additionalInformation"]) {
        let finalData = this.state.editFormExtra;
        let data = {};
        let required = {};
        let fieldInfo = {};
        Object.entries(this.props.userInfo["additionalInformation"]).map(
          ([key, value]) => {
            const info = this.findfieldInfo(key);
            data[key] = value;
            required[key] = consts.createAccount.required[key] || false;
            if (info && info.value) {
              fieldInfo[key] = info.value;
            }
            finalData.data = data;
            finalData.required = required;
            finalData.info = fieldInfo;
            this.setState({ editFormExtra: { ...finalData } });
            return undefined;
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
    if (
      prevState.DownloadDataStep !== this.state.DownloadDataStep &&
      this.state.DownloadDataStep === 1
    ) {
      this.downloadMyData();
    }
    if (!prevProps.userInfo && this.props.userInfo) {
      this.loadDataUserInfo();
    }
    if (
      prevState.editFormDetail.data.email !==
      this.state.editFormDetail.data.email
    ) {
      if (!this.validateEmail(this.state.editFormDetail.data.email)) {
        this.setState({ emailError: "Email address not valid" });
      } else {
        this.setState({ emailError: null });
      }
    }
  }

  loadDataUserInfo() {
    let isDateOfBirthNotValid =
      moment(this.props.userInfo.getBirthdate()).lang("en").format("LLL") !==
      "Invalid date";
    let dateFormat = this.props.t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    let editFormDetail = {
      title: this.props.userInfo.getTitle() || "",
      login: this.props.userInfo.getLogin() || "",
      firstName: this.props.userInfo.getFirstname() || "",
      lastName: this.props.userInfo.getLastname() || "",
      email: this.props.userInfo.getEmail() || "",
      phoneNumber: this.props.userInfo.getPhoneNumber() || "",
      languageId: this.props.userInfo.getLanguageId() || "",
      dateofbirth: isDateOfBirthNotValid
        ? moment(this.props.userInfo.getBirthdate()).format(
            dateFormat.toUpperCase()
          )
        : "0000-00-00",
      additionalInformation:
        this.props.userInfo.getAdditionalInformation() || "",
      allowNews: this.props.userInfo.getAllowNews() || false,
    };
    let editFormAddress = {
      addressNumber: this.props.userInfo.getNumber() || "",
      addressStreet: this.props.userInfo.getStreet() || "",
      addressSecondStreet: this.props.userInfo.getSecondStreet() || "",
      addressThirdStreet: this.props.userInfo.getThirdStreet() || "",
      addressBox: this.props.userInfo.getBox() || "",
      addressFloor: this.props.userInfo.getFloor() || "",
      addressCity: this.props.userInfo.getCity() || "",
      addressZip: this.props.userInfo.getZipcode() || "",
      addressState: this.props.userInfo.getState() || "",
      addressCountry: this.props.userInfo.getCountry() || "",
    };

    this.setState((prevState) => ({
      ...prevState,
      editFormDetail: {
        ...prevState.editFormDetail,
        data: Object.assign({}, editFormDetail),
      },
      editFormAddress: {
        ...prevState.editFormAddress,
        data: Object.assign({}, editFormAddress),
      },
      isNextWithError: false,
      errorFields: [],
    }));
  }

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "changeAddress":
        if (this.errorAPIChangeAddress[code]) {
          error = this.errorAPIChangeAddress[code];
        }
        break;
      case "changeDetails":
        if (this.errorAPIChangeDetails[code]) {
          error = this.errorAPIChangeDetails[code];
        }
        break;
      case "updatePassword":
        if (this.errorAPIUpdatePassword[code]) {
          error = this.errorAPIUpdatePassword[code];
        }
        break;
      default:
        break;
    }

    return error;
  }

  handleClickOnCancelButton() {
    this.setState({ edited: false });
    this.loadDataUserInfo();
  }

  handleClickOnSaveButton() {
    const { editFormDetail, editFormAddress, editFormExtra } = this.state;
    const { t } = this.props;
    const newDetailState = JSON.parse(JSON.stringify(editFormDetail.data));
    if (consts.pageMyAccount.birthday) {
      let dateFormat = t("dateFormat");
      if (dateFormat === "dateFormat") {
        dateFormat = consts.dateFormat;
      }
      if (newDetailState["dateofbirth"] === "Fecha inválida") {
        newDetailState["dateofbirth"] = "1970-01-01";
      }
      const formatedDateOfBirth = moment(newDetailState["dateofbirth"], [
        dateFormat.toUpperCase(),
        "YYYY-MM-DD",
      ]);
      if (
        moment().diff(formatedDateOfBirth, "years") <
        consts.minimumAgeToRegister
      ) {
        showToast(this.props, {
          text: t("Sorry, you must be at least {{ age }} years old.", {
            age: consts.minimumAgeToRegister,
          }),
          className: "error",
          duration: 6000,
        });
        return;
      }

      if (
        formatedDateOfBirth.date() > 31 ||
        formatedDateOfBirth.month() > 12 ||
        Number.isNaN(formatedDateOfBirth.month()) ||
        Number.isNaN(formatedDateOfBirth.date())
      ) {
        showToast(this.props, {
          text: t("Invalid date of birth"),
          className: "error",
          duration: 6000,
        });
        return;
      }

      if (formatedDateOfBirth.lang("en").format("LLL") !== "Invalid date") {
        newDetailState["dateofbirth"] = moment(newDetailState["dateofbirth"], [
          dateFormat.toUpperCase(),
          "YYYY-MM-DD",
        ]).format("YYYY-MM-DD");
        this.setState((prevState) => ({
          editFormDetail: {
            ...prevState.editFormDetail,
            data: Object.assign({}, newDetailState),
          },
        }));
      }
    } else {
      delete newDetailState.dateofbirth;
    }

    const fieldValid = this.fieldValid();
    if (fieldValid && fieldValid.length === 0) {
      this.props
        .dispatch(TucanoActions.changeAddress(editFormAddress.data))
        .then(() => {
          const { dataOnChangeAddress, dataOnChangeDetails } = this.props;

          if (dataOnChangeAddress && dataOnChangeAddress.newAuthToken) {
            if (editFormExtra.data !== null) {
              newDetailState["additionalInformation"] = JSON.stringify(
                editFormExtra.data
              );
            } else {
              delete newDetailState["additionalInformation"];
            }
            this.props
              .dispatch(TucanoActions.changeDetails(newDetailState))
              .then(() => {
                const { dataOnChangeDetails } = this.props;

                if (dataOnChangeDetails && dataOnChangeDetails.newAuthToken) {
                  showToast(this.props, {
                    text: t("Your account has been successfully updated"),
                    className: "primary",
                    duration: 6000,
                  });
                  this.setState({
                    edited: false,
                  });
                  this.props
                    .dispatch(TucanoActions.getAccountInfos())
                    .then((_data) => {
                      if (this.state.langEdited) window.location.reload();
                    });
                } else if (dataOnChangeDetails && dataOnChangeDetails.code) {
                  if (dataOnChangeDetails?.details["phoneNumbers[0].number"]) {
                    if (dataOnChangeDetails && dataOnChangeDetails.code) {
                      showToast(this.props, {
                        text: t("phone number must be maximum 15"),
                        className: "error",
                        duration: 6000,
                      });
                    }
                  } else {
                    if (dataOnChangeDetails && dataOnChangeDetails.code) {
                      showToast(this.props, {
                        text: t(
                          this.getErrorAPI(
                            "changeDetails",
                            dataOnChangeDetails.code
                          )
                        ),
                        className: "error",
                        duration: 6000,
                      });
                    }
                  }
                }
                if (consts.appModules.profiles === false) {
                  saveInLocal("languageId", newDetailState.languageId);
                  this.props.i18n.changeLanguage(
                    this.state.languageIdMapping[newDetailState.languageId]
                  );
                }
              });
          } else {
            if (dataOnChangeAddress && dataOnChangeAddress.code) {
              showToast(this.props, {
                text: t(
                  this.getErrorAPI("changeAddress", dataOnChangeAddress.code)
                ),
                className: "error",
                duration: 6000,
              });
            }
            if (dataOnChangeDetails && dataOnChangeDetails.code) {
              showToast(this.props, {
                text: t(
                  this.getErrorAPI("changeDetails", dataOnChangeDetails.code)
                ),
                className: "error",
                duration: 6000,
              });
            }
          }
        });
    } else {
      this.setState(
        { isNextWithError: true, errorFields: fieldValid },
        () => {}
      );
      let toastTextError = [];
      fieldValid.forEach((elem) => {
        toastTextError.push(t(elem));
      });
      showToast(this.props, {
        text:
          t("Please to check the following field(s)") +
          " : " +
          toastTextError.join(", "),
        className: "error",
        duration: 6000,
      });
    }
  }

  handleChangeDetailValue(inputId, value) {
    const newDetailState = this.state.editFormDetail.data;
    const fieldValid = this.fieldValid();
    if (newDetailState[inputId] !== value) {
      newDetailState[inputId] = value;

      this.setState({
        edited: true,
      });

      if (inputId === "languageId") {
        this.setState({
          langEdited: true,
        });
      }
    }

    this.setState((prevState) => ({
      editFormDetail: {
        ...prevState.editFormDetail,
        data: Object.assign({}, newDetailState),
      },
      isNextWithError: fieldValid.length > 0,
      errorFields: fieldValid,
    }));
  }

  handleChangeAddressValue(inputId, value) {
    let newAddressState = this.state.editFormAddress.data;
    if (newAddressState[inputId] !== value) {
      newAddressState[inputId] = value;

      this.setState({
        edited: true,
      });
    }
  }

  handleChangeExtraValue(inputId, value) {
    let newExtraState = this.state.editFormExtra.data;
    const fieldValid = this.fieldValid();

    if (newExtraState[inputId] !== value) {
      newExtraState[inputId] = value;

      this.setState({
        edited: true,
      });
    }

    this.setState((prevState) => ({
      editFormExtra: {
        ...prevState.editFormExtra,
        data: Object.assign({}, newExtraState),
      },
      isNextWithError: fieldValid.length > 0,
      errorFields: fieldValid,
    }));
  }

  showModal(modalType) {
    this.setState({ show: true, modalType: modalType });
  }

  hideModal(modalType) {
    this.setState({
      show: false,
      modalType: modalType,
      PasswordToConfirm: "",
      deleteConfirmButtonSpin: false,
      deleteDataErrorMessage: "",
    });
  }

  handlePasswordSubmit(old, password, confirm) {
    const { userInfo, t } = this.props;
    const email = userInfo.email;

    this.props
      .dispatch(TucanoActions.updatePassword(email, old, password, confirm))
      .then(() => {
        const { dataOnUpdatePassword } = this.props;

        if (dataOnUpdatePassword && dataOnUpdatePassword.newAuthToken) {
          showToast(this.props, {
            text: t("Your password has been successfully updated"),
            className: "primary",
            duration: 6000,
          });
          this.hideModal(MODAL_PASSWORD);
        }

        if (dataOnUpdatePassword && dataOnUpdatePassword.code) {
          showToast(this.props, {
            text: t(
              this.getErrorAPI("updatePassword", dataOnUpdatePassword.code)
            ),
            className: "error",
            duration: 6000,
          });
        }
      })
      .catch((error) => {
        showToast(this.props, {
          text: t(this.getErrorAPI("updatePassword", error)),
          className: "error",
          duration: 6000,
        });
      });
  }

  handleResetPassword() {
    const { userInfo } = this.props;
    const email = userInfo.email;
    this.props.dispatch(TucanoActions.askResetPassword(email));
    this.hideModal(MODAL_PASSWORD);
  }

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  fieldValid() {
    const { editFormDetail, editFormAddress, editFormExtra } = this.state;
    let dateFormat = this.props.t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    let errorField = [];

    for (let i in editFormDetail.data) {
      if (i === "email") {
        errorField.push(i);
        if (!this.validateEmail(editFormDetail.data[i])) {
          this.setState({ emailError: "Email address not valid" });
        } else {
          errorField = errorField.filter((item) => item !== i);
          this.setState({ emailError: null });
        }
      }
      if (
        editFormDetail.hasOwnProperty("required") &&
        editFormDetail.required[i] === true
      ) {
        if (
          editFormDetail.data[i] === "" ||
          editFormDetail.data[i] === undefined ||
          editFormDetail.data[i] === null
        ) {
          errorField.push(i);
        }
      }
      if (
        i === "dateofbirth" &&
        editFormDetail.hasOwnProperty("required") &&
        editFormDetail.required[i] === true
      ) {
        let date = moment(editFormDetail.data[i], [
          dateFormat.toUpperCase(),
          "YYYY-MM-DD",
        ]).format(dateFormat.toUpperCase());
        if (
          moment(editFormDetail.data[i], [
            dateFormat.toUpperCase(),
            "YYYY-MM-DD",
          ])
            .lang("en")
            .format("LLL") === "Invalid date"
        ) {
          date = moment(editFormDetail.data[i], "YYYY-MM-DD").format(
            dateFormat.toUpperCase()
          );
        }
        if (date === "Invalid date") errorField.push(i);
      }
    }
    for (let j in editFormAddress.data) {
      if (
        editFormAddress.hasOwnProperty("required") &&
        editFormAddress.required[j] === true
      ) {
        if (
          editFormAddress.data[j] === "" ||
          editFormAddress.data[j] === undefined ||
          editFormAddress.data[j] === null
        ) {
          errorField.push(j);
        }
      }
    }
    for (let k in editFormExtra.data) {
      if (
        editFormExtra.hasOwnProperty("required") &&
        editFormExtra.required[k] === true
      ) {
        if (
          editFormExtra.data[k] === "" ||
          editFormExtra.data[k] === undefined ||
          editFormExtra.data[k] === null
        ) {
          errorField.push(k);
        }
      }
    }
    return errorField;
  }

  findfieldInfo = (key) => {
    let found;
    consts.steps.forEach((step) => {
      if (step.subSteps) {
        step.subSteps.forEach((sub) => {
          if (sub.data) {
            found = sub.data.find((s) => s.key === key);
          }
        });
      }
    });
    return found;
  };

  onChangePasswordToConfirm = (PasswordToConfirm) => {
    this.setState({ PasswordToConfirm });
  };

  onConfirmDeleteWithPassword = async () => {
    const { PasswordToConfirm } = this.state;
    const { userInfo, t, dispatch } = this.props;
    const { email } = userInfo;
    const updatedState = {
      deleteConfirmButtonSpin: false,
      PasswordToConfirm: "",
    };

    if (PasswordToConfirm) {
      this.setState({ deleteConfirmButtonSpin: true });
      const result = await dispatch(
        TucanoActions.checkPassword(email, PasswordToConfirm)
      );

      if (result.newAuthToken) {
        updatedState.modalType = MODAL_DELETE_DATA_STEP2;
      } else {
        updatedState.deleteDataErrorMessage = t("Wrong credentials try again");
        updatedState.modalType = MODAL_DELETE_DATA_ERROR;
      }
      this.setState(updatedState);
    }
  };

  onConfirmDeleteAccount = async () => {
    const { dispatch } = this.props;
    const updatedState = {};
    const result = await dispatch(TucanoActions.deleteMyAccount());
    if (result.code && [-1, -2374, -2375, -2376, -9000].includes(result.code)) {
      updatedState.deleteDataErrorMessage = result.message || "";
      updatedState.modalType = MODAL_DELETE_DATA_ERROR;
    } else {
      updatedState.modalType = MODAL_DELETE_DATA_STEP3;
    }

    this.setState(updatedState);
  };

  onRequestExportAccount = async () => {
    const { dispatch } = this.props;

    if (getFromLocal("idJobQueue") === null) {
      const response = await dispatch(TucanoActions.requestExportAccount());

      if (response.idJobQueue) {
        saveInLocal("idJobQueue", response.idJobQueue);
        this.setState({ DownloadDataStep: 1 });
      } else if (response.path && response.status === "DONE") {
        this.setState({ DownloadDataStep: 2, DownloadLink: response.path });
      }
    } else {
      this.setState({ DownloadDataStep: 1 });
    }
  };

  downloadMyData = async () => {
    const { dispatch } = this.props;

    let idJobQueue = getFromLocal("idJobQueue");

    if (idJobQueue !== null) {
      const response = await dispatch(
        TucanoActions.getExportAccount(idJobQueue)
      );

      if (response.message === "invalid idJobQueue") {
        removeFromLocal("idJobQueue");
        this.setState({ DownloadDataStep: 0 });
        return;
      }

      switch (response.status) {
        case "ORDERED":
          this.setState({ DownloadDataStep: 1, DownloadDataError: false });
          break;
        case "IN_PROGRESS":
          this.setState({ DownloadDataStep: 1, DownloadDataError: false });
          break;
        case "DONE":
          this.setState({
            DownloadDataStep: 2,
            DownloadDataError: false,
            DownloadLink: response.path,
          });
          break;
        default:
          this.setState({ DownloadDataStep: 0, DownloadDataError: true });
          removeFromLocal("idJobQueue");
          break;
      }
    }
  };

  onLogout = () => {
    this.props.history.push(consts.routes.logout.url);
  };

  parentalCode = (title) => {
    const { t } = this.props;
    const { show, modalType } = this.state;
    return (
      <>
        <Button
          rootClassName={style.button}
          type={ButtonType.GHOST}
          onClick={this.showModal.bind(this, MODAL_PARENTAL_CODE)}
        >
          {title ? t(title) : <Trans>Parental code</Trans>}
        </Button>
        {show && modalType === MODAL_PARENTAL_CODE && (
          <Modal
            size={"sm"}
            leftContent={{ title: t("Change parental code") }}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_PARENTAL_CODE),
            }}
            rootClassName={style.modal}
            overlay={true}
          >
            <FormChangeParentalCode
              closeModal={this.hideModal.bind(this)}
              rootClassName={style.modal}
            />
          </Modal>
        )}
      </>
    );
  };

  deleteAccount = (title = "", index, url = "", type = "interal") => {
    const { t } = this.props;
    const {
      show,
      modalType,
      PasswordToConfirm,
      deleteConfirmButtonSpin,
      deleteDataErrorMessage,
    } = this.state;
    return (
      <>
        <div
          className={classnames(style.myDataContent, {
            [style.myDataContentSso]: consts.graphQLGrantType !== "password",
            [style.top_border]:
              consts.graphQLGrantType !== "password" && index > 0,
            [style.bottom_border]:
              consts.graphQLGrantType !== "password" &&
              index < consts?.implicitSsoAccountActions?.length - 1,
          })}
        >
          <p className={style.myDataTitle}>
            <Trans>Delete my account</Trans>
          </p>
          <p className={style.myDataDescription}>
            <Trans>
              If you choose to delete your account, we will erase your data from
              our database.
            </Trans>
            <br />
            <Trans>
              This action is irreversible, you will no longer be able to use our
              services with this account and lose all benefits of this account.
            </Trans>
          </p>
          {consts.graphQLGrantType !== "password" ? (
            type === "internal" ? (
              <Button
                rootClassName={style.button}
                type={ButtonType.GHOST}
                onClick={() => this.showModal(MODAL_DELETE_DATA_STEP1)}
              >
                {title ? t(title) : <Trans>Delete my account</Trans>}
              </Button>
            ) : (
              <MyAccountButton
                url={url}
                key={index}
                type={ButtonType.GHOST}
                rootClassName={style.button}
                title={title}
                customerAuthToken={this.props.customerAuthToken}
                t={t}
                dispatch={this.props.dispatch}
              />
            )
          ) : (
            <a
              className={style.btnLink}
              onClick={() => this.showModal(MODAL_DELETE_DATA_STEP1)}
            >
              {title ? t(title) : <Trans>I want to delete my account</Trans>}
            </a>
          )}
        </div>
        {show && modalType === MODAL_DELETE_DATA_STEP1 && (
          <Modal
            size={"sm"}
            leftContent={{ title: t("Delete my account") }}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_DELETE_DATA_STEP1),
            }}
            rootClassName={style.modalDeleteConfirm}
            overlay={true}
          >
            <div className="confirm-delete-modal">
              <p className={style.deleteModalText}>
                <Trans>Please enter your password to confirm</Trans>
              </p>
              <div className={style.formConfirm}>
                {/* <p className={style.formPasswordTitle}>
                  <Trans>Enter your password</Trans>
                </p> */}
                <Input
                  rootClassName={style.formPasswordInput}
                  type={InputType.PASSWORD}
                  placeholder={t("Enter your password")}
                  onChange={(value) => this.onChangePasswordToConfirm(value)}
                  value={PasswordToConfirm}
                />
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalDeleteConfirmButton}
                  type={
                    deleteConfirmButtonSpin
                      ? ButtonType.LOADING
                      : PasswordToConfirm
                      ? ButtonType.NORMAL
                      : ButtonType.DISABLED
                  }
                  onClick={() => this.onConfirmDeleteWithPassword()}
                >
                  <Trans>Delete my account</Trans>
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {show && modalType === MODAL_DELETE_DATA_STEP2 && (
          <Modal
            size={"sm"}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_DELETE_DATA_STEP2),
            }}
            rootClassName={style.modalDeleteConfirm}
            overlay={true}
          >
            <div className="confirm-delete-modal">
              <div className={style.modalConfirmBody}>
                <AttentionIcon className={style.attentionIcon} />
                <p className={style.confirmTextMsg}>
                  <Trans>Are you sure you want to delete your account ?</Trans>
                </p>
                <p className={style.confirmTextDesc}>
                  <Trans>
                    By confirming you will lose all your benefits and no longer
                    be able to use our service with this account
                  </Trans>
                </p>
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalDeleteConfirmButton2}
                  type={ButtonType.NORMAL}
                  onClick={() => this.onConfirmDeleteAccount()}
                >
                  <Trans>Confirm</Trans>
                </Button>
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalDeleteConfirmButton2}
                  type={ButtonType.NORMAL}
                  onClick={() => this.hideModal(MODAL_DELETE_DATA_STEP2)}
                >
                  <Trans>Cancel</Trans>
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {show && modalType === MODAL_DELETE_DATA_ERROR && (
          <Modal
            size={"sm"}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_DELETE_DATA_ERROR),
            }}
            rootClassName={style.modalDeleteError}
            overlay={true}
          >
            <div className="confirm-delete-modal">
              <div className={style.modalConfirmBody}>
                <p className={style.errorTextMsg}>
                  <Trans>Something went wrong</Trans>
                </p>
                <p className={style.errorTextDesc}>{deleteDataErrorMessage}</p>
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalErrorConfirmButton}
                  type={ButtonType.NORMAL}
                  onClick={() => this.hideModal(MODAL_DELETE_DATA_ERROR)}
                >
                  <Trans>Close</Trans>
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {show && modalType === MODAL_DELETE_DATA_STEP3 && (
          <Modal
            size={"sm"}
            rightContent={{ canDismiss: () => this.onLogout() }}
            rootClassName={style.modalDeleteLogout}
            overlay={true}
          >
            <div className="confirm-delete-modal">
              <div className={style.modalConfirmBody}>
                <p className={style.infoTextMsg}>
                  <Trans>As requested your data has been deleted</Trans>
                </p>
                <p className={style.infoTextDesc}>
                  <Trans>Your account will be logged out automatically</Trans>
                </p>
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalInfoConfirmButton}
                  type={ButtonType.NORMAL}
                  onClick={() => this.onLogout()}
                >
                  <Trans>Ok</Trans>
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  };

  renderEditPasswordModal = () => {
    const { t } = this.props;
    const { show, modalType } = this.state;
    return (
      <>
        {show && modalType === MODAL_PASSWORD && (
          <Modal
            size={"sm"}
            leftContent={{ title: t("Change password") }}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_PASSWORD),
            }}
            rootClassName={style.modal}
            overlay={true}
          >
            <FormChangePassword
              onSubmitClick={this.handlePasswordSubmit.bind(this)}
              onResetLinkClick={this.handleResetPassword.bind(this)}
            />
          </Modal>
        )}
      </>
    );
  };

  render() {
    const {
      loadingOnChangeDetails,
      loadingOnChangeAddress,
      t,
      userInfo,
      accountDetail,
      activeProfile,
    } = this.props;
    const {
      editFormDetail,
      editFormAddress,
      optionsLanguage,
      optionsTitle,
      optionsAddressCountry,
      errorFields,
      edited,
      editFormExtra,
      DownloadDataStep,
      DownloadDataError,
      DownloadLink,
    } = this.state;
    const isLocalIsp = isConnectedFromLocalISP();
    const authMode = getFromLocal("authMode");
    const authTypes = {
      CREDENTIAL: "credential",
      SSOOPERATOR: "ssooperator",
      SSOSOCIAL: "ssosocial",
    };
    const circleStyle = accountDetail
      ? accountDetail.status === "ACTIVE"
        ? style.accountActive
        : style.accountInactive
      : style.accountInactive;
    const shapeStyle = accountDetail
      ? accountDetail.status === "ACTIVE"
        ? { width: "65px" }
        : { width: "88px" }
      : { width: "88px" };

    const country = optionsAddressCountry.map((lang) => {
      return { label: t(lang.label), value: lang.value };
    });
    const languages = optionsLanguage?.map((lang) => ({
      label: t(lang.label),
      value: lang.value,
    }));
    const direction = getFromLocal("layoutDirection");
    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    if (
      consts.graphQLGrantType !== "password" &&
      authMode !== authTypes.SSOSOCIAL
    ) {
      return (
        <ContentWrapper>
          <div className={style.root}>
            <Fragment>
              <Header>
                <h1 className={classnames(style.title, style.implicitTitle)}>
                  <Trans>{this.props.route.title}</Trans>
                </h1>
                <div className={style.header}>
                  <div className={style.profilImage} alt="Profil">
                    <Image
                      src={consts.implicitSsoProviderLogo}
                      width={50}
                      height={50}
                      placeholder={PlaceholderType.PROFILE}
                    />
                  </div>
                  <div className={style.headerContainer}>
                    <div className={style.fullName}>
                      {editFormDetail.data.firstName}
                    </div>
                    <h1 className={style.title}>
                      <Trans t={t}>implicit_sso_headline_format</Trans>{" "}
                      {consts.implicitSsoProviderName}
                    </h1>
                  </div>
                </div>
              </Header>
              <div className={style.actionsContaier}>
                {consts.implicitSsoAccountActions.map((action, index) => {
                  if (
                    action.title === "action_implicit_sso_parental_code" &&
                    action.type === "internal"
                  ) {
                    return this.parentalCode(action.title);
                  } else if (
                    action.title === "action_implicit_sso_delete_account"
                  ) {
                    return this.deleteAccount(
                      action.title,
                      index,
                      action.url,
                      action.type
                    );
                  } else if (
                    action.title === "action_implicit_sso_edit_password" &&
                    action.type === "internal"
                  ) {
                    return (
                      <>
                        <Button
                          rootClassName={style.button}
                          type={ButtonType.GHOST}
                          onClick={this.showModal.bind(this, MODAL_PASSWORD)}
                        >
                          {t(action.title)}
                        </Button>
                        {this.renderEditPasswordModal()}
                      </>
                    );
                  } else if (action.type === "internal") {
                    return (
                      <Button
                        key={index}
                        rootClassName={style.button}
                        type={ButtonType.GHOST}
                        to={action.url}
                      >
                        <Trans>{action.title}</Trans>
                      </Button>
                    );
                  } else if (action.type === "external") {
                    return (
                      <MyAccountButton
                        url={action.url}
                        key={index}
                        type={ButtonType.GHOST}
                        rootClassName={style.button}
                        title={action.title}
                        customerAuthToken={this.props.customerAuthToken}
                        t={t}
                        dispatch={this.props.dispatch}
                      />
                    );
                  }
                  return <></>;
                })}
              </div>
            </Fragment>
          </div>
        </ContentWrapper>
      );
    }

    return (
      <ContentWrapper>
        <div className={style.root}>
          <Fragment>
            <Header>
              <div className={style.header}>
                <div className={style.profilImage} alt="Profil">
                  {activeProfile &&
                  activeProfile.getPicture() &&
                  activeProfile.getPicture() !== null ? (
                    <Image
                      width={50}
                      height={50}
                      src={`${consts.image_hostname}${
                        routes.imgdata
                      }?type=profile&objectId=${activeProfile.getPicture()}&format_w=50&format_h=50&languageId=${getFromLocal(
                        "languageId"
                      )}`}
                      ratio={ImageRatio.CIRCLE}
                      placeholder={PlaceholderType.PROFILE}
                    />
                  ) : (
                    <div className={style.profilImagePlaceholder}></div>
                  )}
                </div>
                <div className={style.headerContainer}>
                  <h1 className={style.title}>
                    <Trans>{this.props.route.title}</Trans>
                  </h1>
                  <div className={style.fullName}>
                    {editFormDetail.data.firstName}{" "}
                    {editFormDetail.data.lastName}
                  </div>
                  <div className={style.shape} style={shapeStyle}>
                    <div className={style.centerwrapper}>
                      <div
                        className={classnames(style.liveShape, circleStyle)}
                      />
                      <div className={style.labelStatus}>
                        {accountDetail && accountDetail.status === "ACTIVE"
                          ? `${t("ACTIVE")}`
                          : `${t("SUSPENDED")}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Header>
            <div className={style.form}>
              <div
                className={classnames(style.buttonContainer, {
                  [style[direction.toUpperCase()]]: true,
                })}
              >
                {authMode !== authTypes.SSOOPERATOR && (
                  <Button
                    rootClassName={style.button}
                    type={ButtonType.GHOST}
                    onClick={this.showModal.bind(this, MODAL_PASSWORD)}
                  >
                    {CapitalizeFirstLetter(t("password"))}
                  </Button>
                )}
                {this.renderEditPasswordModal()}
                {this.parentalCode()}
              </div>
              <div className={style.profileContainer}>
                <h2 className={style.categoryTitle}>
                  <Trans>Profile</Trans>
                </h2>
                {consts.pageMyAccount.title && (
                  <div className={style.section}>
                    <p className={style.label}>
                      {consts.specificTitleLabel ? (
                        <>
                          {CapitalizeFirstLetter(t(consts.specificTitleLabel))}{" "}
                          {editFormDetail.required.title && (
                            <span className={style.asterisk}>*</span>
                          )}
                        </>
                      ) : (
                        <>
                          {CapitalizeFirstLetter(t("title"))}{" "}
                          {editFormDetail.required.title && (
                            <span className={style.asterisk}>*</span>
                          )}
                        </>
                      )}
                    </p>
                    <Select
                      isSearchable={false}
                      options={optionsTitle}
                      isDisabled={authMode === authTypes.SSOOPERATOR}
                      defaultValue={optionsTitle.filter(
                        ({ value }) => value === editFormDetail.data.title
                      )}
                      placeholder={t("Choose your title")}
                      className={style.selectContainer}
                      classNamePrefix={"react-select"}
                      onChange={(value) =>
                        this.handleChangeDetailValue("title", value)
                      }
                      required={editFormDetail.required.title}
                      autoComplete={"honorific-prefix"}
                      error={errorFields && errorFields.includes("title")}
                    />
                  </div>
                )}
                <div className={style.section}>
                  <p className={style.label}>
                    <Trans>Username</Trans>
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    value={editFormDetail.data.login}
                    disabled={true}
                  />
                </div>
                <div className={style.section}>
                  <p className={style.label}>
                    {CapitalizeFirstLetter(t("firstName"))}{" "}
                    {editFormDetail.required.firstName && (
                      <span className={style.asterisk}>*</span>
                    )}
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    value={editFormDetail.data.firstName}
                    onChange={(value) => {
                      this.handleChangeDetailValue("firstName", value);
                    }}
                    disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                    required={editFormDetail.required.firstName}
                    error={errorFields && errorFields.includes("firstName")}
                    autoComplete={"given-name"}
                  />
                </div>
                <div className={style.section}>
                  <p className={style.label}>
                    {CapitalizeFirstLetter(t("last name"))}{" "}
                    {editFormDetail.required.lastName && (
                      <span className={style.asterisk}>*</span>
                    )}
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                    value={editFormDetail.data.lastName}
                    onChange={(value) => {
                      this.handleChangeDetailValue("lastName", value);
                    }}
                    required={editFormDetail.required.lastName}
                    autoComplete={"family-name"}
                    error={errorFields && errorFields.includes("lastName")}
                  />
                </div>
                {consts.pageMyAccount.birthday && (
                  <div className={style.section}>
                    <p className={style.label}>
                      {CapitalizeFirstLetter(t("dateofbirth"))}{" "}
                      {editFormDetail.required.dateofbirth && (
                        <span className={style.asterisk}>*</span>
                      )}
                    </p>
                    <Input
                      rootClassName={style.value}
                      type={InputType.MASK}
                      disabled={
                        authMode === authTypes.SSOOPERATOR ? true : false
                      }
                      placeholder={dateFormat}
                      value={editFormDetail.data.dateofbirth}
                      onChange={(value) => {
                        this.handleChangeDetailValue("dateofbirth", value);
                      }}
                      pattern={"DATEOFBIRTH"}
                      required={editFormDetail.required.dateofbirth}
                      autoComplete={"bday"}
                      error={errorFields && errorFields.includes("dateofbirth")}
                    />
                  </div>
                )}
                <div className={style.section}>
                  <p className={style.label}>
                    <Trans>Preferred language</Trans>{" "}
                    {editFormDetail.required.languageId && (
                      <span className={style.asterisk}>*</span>
                    )}
                  </p>
                  <Select
                    searchable={false}
                    options={languages}
                    defaultValue={languages.filter(
                      ({ value }) => value === editFormDetail.data.languageId
                    )}
                    placeholder={t("Choose your preferred language")}
                    className={style.selectContainer}
                    classNamePrefix={"react-select"}
                    //hideSelectedOptions={true}
                    isDisabled={authMode === authTypes.SSOOPERATOR}
                    onChange={(value) =>
                      this.handleChangeDetailValue("languageId", value)
                    }
                    required={editFormDetail.required.languageId}
                    autoComplete={"language"}
                  />
                </div>
              </div>
            </div>

            <div className={style.contactContainer}>
              <h2 className={style.categoryTitle}>
                <Trans>Contact</Trans>
              </h2>
              <div className={style.section}>
                <p className={style.label}>
                  {consts.specificEmailLabel ? (
                    <>
                      {CapitalizeFirstLetter(t(consts.specificEmailLabel))}
                      {editFormDetail.required.email && (
                        <span className={style.asterisk}>*</span>
                      )}
                    </>
                  ) : (
                    <>
                      {CapitalizeFirstLetter(t("email"))}{" "}
                      {editFormDetail.required.email && (
                        <span className={style.asterisk}>*</span>
                      )}
                    </>
                  )}
                </p>
                <Input
                  type={InputType.TEXT}
                  rootClassName={style.value}
                  value={editFormDetail.data.email}
                  disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                  onChange={(value) => {
                    this.handleChangeDetailValue("email", value);
                  }}
                  required={editFormDetail.required.email}
                  error={errorFields && errorFields.includes("email")}
                />
              </div>
              {consts.pageMyAccount.phone && (
                <div className={style.section}>
                  <p className={style.label}>
                    <Trans>Phone number</Trans>{" "}
                    {editFormDetail.required.phoneNumber && (
                      <span className={style.asterisk}>*</span>
                    )}
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                    value={editFormDetail.data.phoneNumber}
                    onChange={(value) => {
                      this.handleChangeDetailValue("phoneNumber", value);
                    }}
                    required={editFormDetail.required.phoneNumber}
                    error={errorFields && errorFields.includes("phoneNumber")}
                  />
                </div>
              )}
              <h2 className={style.categoryTitle}>
                <Trans>Address</Trans>
              </h2>
              {consts.pageMyAccount.adresses && (
                <div className={style.section}>
                  <div className={style.numberContainer}>
                    <p className={style.label}>
                      {t("addressNumber")}{" "}
                      {editFormAddress.required.addressNumber && (
                        <span className={style.asterisk}>*</span>
                      )}
                    </p>
                    <Input
                      type={InputType.TEXT}
                      rootClassName={style.value}
                      disabled={
                        authMode === authTypes.SSOOPERATOR ? true : false
                      }
                      value={editFormAddress.data.addressNumber}
                      onChange={(value) => {
                        this.handleChangeAddressValue("addressNumber", value);
                      }}
                      required={editFormAddress.required.addressNumber}
                      autoComplete={"none"}
                      error={
                        errorFields && errorFields.includes("addressNumber")
                      }
                    />
                  </div>
                  <div className={style.addressContainer}>
                    <p className={style.label}>
                      <Trans>Address line</Trans> 1{" "}
                      {editFormAddress.required.addressStreet && (
                        <span className={style.asterisk}>*</span>
                      )}
                    </p>
                    <Input
                      type={InputType.TEXT}
                      rootClassName={style.value}
                      disabled={
                        authMode === authTypes.SSOOPERATOR ? true : false
                      }
                      value={editFormAddress.data.addressStreet}
                      onChange={(value) => {
                        this.handleChangeAddressValue("addressStreet", value);
                      }}
                      required={editFormAddress.required.addressStreet}
                      autoComplete={"address-line1"}
                      error={
                        errorFields && errorFields.includes("addressStreet")
                      }
                    />
                  </div>
                </div>
              )}
              {consts.pageMyAccount.adresses && (
                <div className={style.section}>
                  <p className={style.label}>
                    <Trans>Address line</Trans> 2
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                    value={editFormAddress.data.addressSecondStreet}
                    onChange={(value) => {
                      this.handleChangeAddressValue(
                        "addressSecondStreet",
                        value
                      );
                    }}
                    autoComplete={"address-line2"}
                    error={
                      errorFields && errorFields.includes("addressSecondStreet")
                    }
                  />
                </div>
              )}
              {consts.pageMyAccount.adresses && (
                <div className={style.section}>
                  <p className={style.label}>
                    <Trans>Address line</Trans> 3
                  </p>
                  <Input
                    type={InputType.TEXT}
                    rootClassName={style.value}
                    disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                    value={editFormAddress.data.addressThirdStreet}
                    onChange={(value) => {
                      this.handleChangeAddressValue(
                        "addressThirdStreet",
                        value
                      );
                    }}
                    autoComplete={"address-line3"}
                    error={
                      errorFields && errorFields.includes("addressThirdStreet")
                    }
                  />
                </div>
              )}
              {consts.pageMyAccount.adresses && (
                <p className={style.label}>
                  <Trans>Box</Trans>
                </p>
              )}
              {consts.pageMyAccount.adresses && (
                <Input
                  type={InputType.TEXT}
                  rootClassName={style.value}
                  disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                  value={editFormAddress.data.addressBox}
                  onChange={(value) => {
                    this.handleChangeAddressValue("addressBox", value);
                  }}
                  autoComplete={"address-box"}
                  error={errorFields && errorFields.includes("addressBox")}
                />
              )}
            </div>
            {consts.pageMyAccount.adresses && (
              <div className={style.section}>
                <p className={style.label}>
                  {consts.specificCityLabel
                    ? CapitalizeFirstLetter(t(consts.specificCityLabel))
                    : CapitalizeFirstLetter(t("city"))}{" "}
                  {editFormAddress.required.addressCity && (
                    <span className={style.asterisk}>*</span>
                  )}
                </p>
                <Input
                  type={InputType.TEXT}
                  rootClassName={style.value}
                  disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                  value={editFormAddress.data.addressCity}
                  onChange={(value) => {
                    this.handleChangeAddressValue("addressCity", value);
                  }}
                  required={editFormAddress.required.addressCity}
                  autoComplete={"locality"}
                  error={errorFields && errorFields.includes("addressCity")}
                />
              </div>
            )}
            {consts.pageMyAccount.adresses && (
              <div className={style.section}>
                <p className={style.label}>
                  <Trans>Postal code</Trans>{" "}
                  {editFormAddress.required.addressZip && (
                    <span className={style.asterisk}>*</span>
                  )}
                </p>
                <Input
                  type={InputType.TEXT}
                  rootClassName={style.value}
                  disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                  value={editFormAddress.data.addressZip}
                  onChange={(value) => {
                    this.handleChangeAddressValue("addressZip", value);
                  }}
                  required={editFormAddress.required.addressZip}
                  autoComplete={"postal-code"}
                  error={errorFields && errorFields.includes("addressZip")}
                />
              </div>
            )}
            {consts.pageMyAccount.adresses && (
              <div className={style.section}>
                <p className={style.label}>
                  {consts.specificStateLabel ? (
                    <Trans>{consts.specificStateLabel}</Trans>
                  ) : (
                    <Trans>State</Trans>
                  )}{" "}
                  {editFormAddress.required.addressState && (
                    <span className={style.asterisk}>*</span>
                  )}
                </p>
                <Input
                  type={InputType.TEXT}
                  rootClassName={style.value}
                  disabled={authMode === authTypes.SSOOPERATOR ? true : false}
                  value={editFormAddress.data.addressState}
                  onChange={(value) => {
                    this.handleChangeAddressValue("addressState", value);
                  }}
                  required={editFormAddress.required.addressState}
                  autoComplete={"region"}
                  error={errorFields && errorFields.includes("addressState")}
                />
              </div>
            )}
            <div className={style.section}>
              <div className={style.selectContainer}>
                <p className={style.label}>
                  {CapitalizeFirstLetter(t("country"))}{" "}
                  {editFormAddress.required.addressCountry && (
                    <span className={style.asterisk}>*</span>
                  )}
                </p>
                <Select
                  isSearchable={false}
                  isDisabled={authMode === authTypes.SSOOPERATOR}
                  options={country}
                  defaultValue={country.filter(
                    ({ value }) => value === editFormAddress.data.addressCountry
                  )}
                  placeholder={t("Choose your country")}
                  classNamePrefix={"react-select"}
                  onChange={(value) =>
                    this.handleChangeAddressValue("addressCountry", value)
                  }
                  required={editFormAddress.required.addressCountry}
                  autoComplete={"country"}
                  error={errorFields && errorFields.includes("addressCountry")}
                />
              </div>
            </div>
            {consts.pageMyAccount.newsletter && (
              <div
                className={classnames(style.containerCheckBox, {
                  [style.containerCheckBoxWithFooter]: isLocalIsp,
                })}
              >
                <Checkbox
                  onChange={(value) =>
                    this.handleChangeDetailValue("allowNews", value)
                  }
                  checked={editFormDetail.data.allowNews}
                  label={
                    <span className={style.terms}>
                      <Trans>
                        I agree to receive newsletters and promotional emails
                      </Trans>{" "}
                    </span>
                  }
                  error={errorFields && errorFields.includes("allowNews")}
                />
              </div>
            )}
            {userInfo?.additionalInformation &&
              Object.keys(userInfo.additionalInformation).length > 1 && (
                <>
                  <div className={style.contactContainer}>
                    <h2 className={style.categoryTitle}>
                      <Trans>Additional information</Trans>
                    </h2>
                    {Object.entries(userInfo.additionalInformation).map(
                      ([key, _val]) => {
                        return (
                          <>
                            {key !== "wecare_reference" &&
                              editFormExtra?.info &&
                              editFormExtra.info[key] &&
                              editFormExtra.info[key].type !== "checkbox" && (
                                <div className={style.section}>
                                  <p className={style.label}>
                                    {CapitalizeFirstLetter(t(key))}{" "}
                                    {editFormDetail.required[key] && (
                                      <span className={style.asterisk}>*</span>
                                    )}
                                  </p>
                                  {editFormExtra?.info &&
                                    editFormExtra.info[key] &&
                                    editFormExtra.info[key].type === "text" && (
                                      <Input
                                        type={InputType.TEXT}
                                        rootClassName={style.value}
                                        value={editFormExtra.data[key]}
                                        onChange={(value) => {
                                          this.handleChangeExtraValue(
                                            key,
                                            value
                                          );
                                        }}
                                        required={editFormExtra.required.key}
                                        error={
                                          errorFields &&
                                          errorFields.includes(key)
                                        }
                                      />
                                    )}
                                  {editFormExtra?.info &&
                                    editFormExtra.info[key] &&
                                    editFormExtra.info[key].type ===
                                      "select" && (
                                      <Select
                                        isSearchable={false}
                                        options={consts.enum[key]}
                                        defaultValue={consts.enum[key].filter(
                                          ({ value }) =>
                                            value === editFormExtra.data[key]
                                        )}
                                        placeholder={t(
                                          `${editFormExtra.info[key].placeholder}`
                                        )}
                                        className={style.selectContainer}
                                        classNamePrefix={"react-select"}
                                        onChange={(value) =>
                                          this.handleChangeExtraValue(
                                            key,
                                            value
                                          )
                                        }
                                        required={editFormExtra.required.key}
                                        autoComplete={key}
                                        error={
                                          errorFields &&
                                          errorFields.includes(key)
                                        }
                                      />
                                    )}
                                  {editFormExtra?.info &&
                                    editFormExtra.info[key] &&
                                    editFormExtra.info[key].type ===
                                      "checkbox" && (
                                      <Select
                                        isSearchable={false}
                                        options={consts.enum[key]}
                                        defaultValue={consts.enum[key].filter(
                                          ({ value }) =>
                                            value === editFormExtra.data[key]
                                        )}
                                        placeholder={t(
                                          `${editFormExtra.info[key].placeholder}`
                                        )}
                                        className={style.selectContainer}
                                        classNamePrefix={"react-select"}
                                        onChange={(value) =>
                                          this.handleChangeExtraValue(
                                            key,
                                            value
                                          )
                                        }
                                        required={editFormExtra.required.key}
                                        autoComplete={key}
                                        error={
                                          errorFields &&
                                          errorFields.includes(key)
                                        }
                                      />
                                    )}
                                </div>
                              )}
                            {key !== "wecare_reference" &&
                              editFormExtra?.info &&
                              editFormExtra.info[key] &&
                              editFormExtra.info[key].type === "checkbox" && (
                                <div
                                  className={classnames(
                                    style.containerCheckBox,
                                    {
                                      [style.containerCheckBoxWithFooter]:
                                        isLocalIsp,
                                    }
                                  )}
                                >
                                  <Checkbox
                                    onChange={(value) =>
                                      this.handleChangeExtraValue(key, value)
                                    }
                                    checked={editFormExtra.data.key}
                                    label={
                                      <span className={style.terms}>
                                        <Trans>
                                          {editFormExtra.info[key].text}
                                        </Trans>{" "}
                                      </span>
                                    }
                                    error={
                                      errorFields && errorFields.includes(key)
                                    }
                                  />
                                </div>
                              )}
                          </>
                        );
                      }
                    )}
                  </div>
                </>
              )}
            {consts.loginWithSsoSocial && consts.SSOLinkUnlinkSection && (
              <div>
                <Collapsible
                  open={false}
                  trigger={
                    <div className={style.arrowContainer}>
                      <span className={style.categoryTitle}>
                        <Trans>Linked Account</Trans>
                      </span>
                      <ArrowHeading className={style.arrow} />
                    </div>
                  }
                >
                  <div className={style.linkedAccountSocialCard}>
                    {consts.SSOSocials &&
                      consts.SSOSocials.map((social) => {
                        return (
                          <Fragment key={social.name}>
                            <LinkedSocialAccount
                              used={true}
                              socialNetwork={social.name}
                              socialIcon={social.name}
                              emailUsed="farhatamiine@gmail.com"
                            />
                          </Fragment>
                        );
                      })}
                  </div>
                </Collapsible>
              </div>
            )}
            {consts.enableDeleteDownoladUserData && (
              <div className={style.block}>
                <Collapsible
                  open={false}
                  trigger={
                    <div className={style.arrowContainer}>
                      <span className={style.categoryTitle}>
                        <Trans>My data</Trans>
                      </span>
                      <ArrowHeading className={style.arrow} />
                    </div>
                  }
                >
                  <div className={style.myDataContent}>
                    <p className={style.myDataTitle}>
                      <Trans>Download my personal data</Trans>
                    </p>
                    <p className={style.myDataDescription}>
                      <Trans>
                        A file containing your data will be generated and
                        available within 48h. Note that it could take some time
                        to generate your file.
                      </Trans>
                    </p>
                    {DownloadDataStep === 0 && (
                      <Button
                        type={ButtonType.BORDERED}
                        onClick={this.onRequestExportAccount.bind(this)}
                      >
                        <Trans>Download my personal data</Trans>
                      </Button>
                    )}
                    {DownloadDataStep === 1 && (
                      <p>
                        {" "}
                        <Trans>Generating file</Trans> 1/3
                      </p>
                    )}
                    {DownloadDataStep === 2 && (
                      <a
                        className={style.normalStyle}
                        href={DownloadLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Trans>Download my personal data</Trans>
                      </a>
                    )}
                  </div>
                  {this.deleteAccount()}
                </Collapsible>
              </div>
            )}
            {edited === true && (
              <div
                className={classnames(style.fixedButton, {
                  [style.fixedButtonWithFooter]: isLocalIsp,
                })}
              >
                <span className={style.changeText}>
                  <Trans>Your account has been modified</Trans>
                </span>
                <Button
                  rootClassName={style.buttonCancel}
                  type={ButtonType.GHOST}
                  onClick={this.handleClickOnCancelButton.bind(this)}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  rootClassName={style.buttonSubmit}
                  type={
                    loadingOnChangeDetails || loadingOnChangeAddress
                      ? ButtonType.LOADING
                      : ButtonType.TEXT
                  }
                  onClick={this.handleClickOnSaveButton.bind(this)}
                >
                  <Trans>Save change</Trans>
                </Button>
              </div>
            )}
          </Fragment>
        </div>
        {DownloadDataError && (
          <Modal
            size={"sm"}
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_DELETE_DATA_ERROR),
            }}
            rootClassName={style.modalDownloadError}
            overlay={true}
          >
            <div className="confirm-delete-modal">
              <div className={style.modalConfirmBody}>
                <p className={style.errorTextMsg}>
                  <Trans>Something went wrong</Trans>
                </p>
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  rootClassName={style.modalErrorConfirmButton}
                  type={ButtonType.NORMAL}
                  onClick={() => this.hideModal(MODAL_DELETE_DATA_ERROR)}
                >
                  <Trans>Close</Trans>
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      toasts: state.ui.toasts,
      userInfo: TucanoSelectors.getUserInfo(state),
      accountDetail: state.account.user.data,
      isParentalCodeLoading: state.account.parentalCode.loading,
      loadingOnChangeDetails: state.account.changeDetails.loading,
      loadingOnChangeAddress: state.account.changeAddress.loading,
      dataOnChangeDetails: state.account.changeDetails.data,
      dataOnChangeAddress: state.account.changeAddress.data,
      dataOnUpdatePassword: state.account.updatePassword.data,
      customerAuthToken: state.session.customerAuthToken,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageMyAccount);
